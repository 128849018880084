@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button,
fieldset,
input {
  all: unset;
}

.add__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #00a3ff;
  border-radius: 5px;
}

.dialog__overlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlay__show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog__content {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: content__show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 6;
}

.dialog__content:focus {
  outline: none;
}

.dialog__title {
  margin: 0 0 15px 0;
  color: var(--font-default-color);
  font-size: 1.125rem;
  font-family: var(--font-bold);
}

.dialog__description {
  margin: 0 0 25px 0;
  color: var(--font-default-color);
  font-size: 1rem;
  line-height: 1.5;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}

.button.enable {
  background-color: var(--blue);
  color: #fff;
}

.button.enable:hover {
  background-color: var(--blue-hover);
}

.button.enable:focus {
  box-shadow: 0 0 0 2px var(--blue-hover);
}

.button.disable {
  background-color: rgb(212, 212, 212);
  color: rgb(112, 112, 112);
}

.button.disable:focus {
  box-shadow: 0 0 0 2px var(--blue-hover);
}

.icon__button {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 10px;
  right: 10px;
}

.icon__button:hover {
  background-color: var(--violet-4);
}

.icon__button:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.dialog__content .select__trigger {
  width: 220px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: #f1e4e4;
  border: 1px solid #9b9b9b;
  font-family: var(--font-bold);
  color: var(--font-color-default);
}

.dialog__content .select__trigger:hover {
  background-color: var(--mauve-3);
}

.company__modules
  .table__header
  .filter__row
  .select__trigger[data-placeholder] {
  color: var(--font-color-default);
}

.select__icon {
  color: Var(--violet-11);
}

.select__content {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  z-index: 10;
  border: 1px solid #ccc;
}

.select__viewport {
  padding: 5px;
}

.select__item {
  font-size: 13px;
  line-height: 1;
  color: var(--font-color-default);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}

.select__item[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.select__item[data-highlighted] {
  outline: none;
  background-color: var(--font-color-default);
  color: var(--violet-1);
}

.select__label {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.select__separator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.select__item__indicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.select__scroll__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}

@keyframes overlay__show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes content__show {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
