.loading {
  border: 4px solid transparent;
  border-top-color: var(--ultra-light-gray);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.loading.small {
  width: 10px;
  height: 10px;
}

.loading.medium {
  width: 24px;
  height: 24px;
}

.loading.large {
  width: 32px;
  height: 32px;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
