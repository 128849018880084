.confirm__account {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
}

.confirm__account img {
  width: 320px;
  margin-bottom: 3rem;
}

.confirm__account h1 {
  font-family: var(--font-bold);
  font-size: 1.125rem;
  text-align: center;
}

.confirm__account .back__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: var(--green-hover);
  border-radius: 4px;
  cursor: pointer;
}

.confirm__account .loading {
  margin-bottom: 2rem;
}

.confirm__account .steps {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-bottom: 2rem;
}

.confirm__account .user__type {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.confirm__account .user__type hr {
  width: 100%;
}

.confirm__account .steps .user__type h3 {
  font-family: var(--font-bold);
  margin: 0 0 5px 0;
}
