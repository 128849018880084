@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/violet.css';
@import '@radix-ui/colors/mauve.css';

.login {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
}

.login .form__box__login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
}

.login .logo__app {
  width: 284px;
  margin-bottom: 2rem;
}

/* reset */
input,
button {
  all: unset;
  box-sizing: border-box;
}

.form__box__login .form__box__login__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
}

.form__box__login .form__box__login__title h2 {
  font-family: var(--font-bold);
  font-size: 2rem;
}

.form__box__login .form__box__login__title hr {
  border: 1px solid var(--ultra-light-gray);
  width: 100%;
}

.form__root__login {
  width: 300px;
}

.form__root__login .form__field {
  width: 100%;
  display: grid;
  margin-bottom: 10px;
}

.form__root__login .form__label {
  font-family: var(--font-bold);
  font-size: 15px;
  font-weight: 700;
  line-height: 35px;
  color: var(--ultra-light-gray);
}

.form__root__login .form__message {
  font-size: 13px;
  color: var(--ultra-light-gray);
  opacity: 0.8;
}

.form__root__login .input__login {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  font-family: var(--font-bold);
  font-size: 15px;
  color: var(--ultra-light-gray);
  background-color: #cecece;
  box-shadow: 0 0 0 1px var(--black-a9);
}

.form__root__login .input__login:hover {
  box-shadow: 0 0 0 1px black;
}

.form__root__login .input__login:focus {
  box-shadow: 0 0 0 2px black;
}

.form__root__login .input__login::selection {
  background-color: var(--black-a9);
  color: white;
}

.form__root__login .input__login {
  padding: 0 10px;
  height: 35px;
  line-height: 1;
}

.form__root__login .button__login {
  font-family: var(--font-bold);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 30px;
}

.form__root__login .button__login.violet {
  color: var(--ultra-light-gray);
  border: 1px solid var(--black-a7);
}

.form__root__login .button__login.violet:hover {
  background-color: var(--violet-5);
}

.form__root__login .button__login.green {
  background-color: var(--green-8);
  color: #fff;
  border: 1px solid var(--green-8);
}

.form__root__login .button__login.green:hover {
  background-color: var(--green-7);
}
