@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/indigo.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/purple.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button,
p {
  all: unset;
}

.navigation__menu__root {
  padding-top: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100vw;
  z-index: 1;
}

.navigation__menu__list {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 8px 14px;
  border-radius: 6px;
  list-style: none;
  margin: 0;
}

.navigation__menu__trigger,
.navigation__menu__link {
  padding: 8px 22px;
  outline: none;
  user-select: none;
  line-height: 1;
  border-radius: 4px;
  font-family: var(--font-bold);
  color: var(--font-color-default);
}
.navigation__menu__trigger:focus,
.navigation__menu__link:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}
.navigation__menu__trigger:hover,
.navigation__menu__link:hover {
  background-color: var(--violet-3);
}

.navigation__menu__trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
}

.navigation__menu__link {
  display: block;
  text-decoration: none;
  font-size: 15px;
  line-height: 1;
}

.navigation__menu__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation-duration: 250ms;
  animation-timing-function: ease;
}
.navigation__menu__content[data-motion='from-start'] {
  animation-name: enter__from__left;
}
.navigation__menu__content[data-motion='from-end'] {
  animation-name: enter__from__right;
}
.navigation__menu__content[data-motion='to-start'] {
  animation-name: exit__to__left;
}
.navigation__menu__content[data-motion='to-end'] {
  animation-name: exit__to__right;
}
@media only screen and (min-width: 600px) {
  .navigation__menu__content {
    width: auto;
  }
}

.navigation__menu__indicator {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 10px;
  top: 100%;
  overflow: hidden;
  z-index: 1;
  transition: width, transform 250ms ease;
}
.navigation__menu__indicator[data-state='visible'] {
  animation: fade__in 200ms ease;
}
.navigation__menu__indicator[data-state='hidden'] {
  animation: fade__out 200ms ease;
}

.navigation__menu__viewport {
  position: relative;
  transform-origin: top center;
  margin-top: 10px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  height: var(--radix-navigation-menu-viewport-height);
  transition: width, height, 300ms ease;
}
.navigation__menu__viewport[data-state='open'] {
  animation: scale__in 200ms ease;
}
.navigation__menu__viewport[data-state='closed'] {
  animation: scale__out 200ms ease;
}
@media only screen and (min-width: 600px) {
  .navigation__menu__viewport {
    width: var(--radix-navigation-menu-viewport-width);
  }
}

.list {
  display: grid;
  padding: 22px;
  margin: 0;
  column-gap: 10px;
  list-style: none;
}
@media only screen and (min-width: 600px) {
  .list.one {
    width: 500px;
    grid-template-columns: 0.75fr 1fr;
  }
  .list.two {
    width: 600px;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, 1fr);
  }
}

.list__item__link {
  display: block;
  outline: none;
  text-decoration: none;
  user-select: none;
  padding: 12px;
  border-radius: 6px;
  font-size: 15px;
  line-height: 1;
}
.list__item__link:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}
.list__item__link:hover {
  background-color: var(--mauve-3);
}

.list__item__heading {
  line-height: 1.2;
  margin-bottom: 5px;
  color: var(--font-color-default);
  font-family: var(--font-bold);
}

.list__item__text {
  color: var(--mauve-11);
  line-height: 1.4;
  font-weight: initial;
}

.callout {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--purple-9) 0%, var(--indigo-9) 100%);
  border-radius: 6px;
  padding: 25px;
  text-decoration: none;
  outline: none;
  user-select: none;
}
.callout:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.callout__heading {
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 16px;
  margin-bottom: 7px;
}

.callout__text {
  color: var(--mauve-4);
  font-size: 14px;
  line-height: 1.3;
}

.viewport__position {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 100%;
  left: 0;
  perspective: 2000px;
}

.caret__down {
  position: relative;
  color: var(--violet-10);
  top: 1px;
  transition: transform 250ms ease;
}
[data-state='open'] > .caret__down {
  transform: rotate(-180deg);
}

.arrow {
  position: relative;
  top: 70%;
  background-color: white;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-top-left-radius: 2px;
}

@keyframes enter__from__right {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enter__from__left {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exit__to__right {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(200px);
  }
}

@keyframes exit__to__left {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-200px);
  }
}

@keyframes scale__in {
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scale__out {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}

@keyframes fade__in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade__out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
