@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button,
fieldset,
input {
  all: unset;
}

.add__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #00a3ff;
  border-radius: 5px;
}

.dialog__overlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  z-index: 5;
  animation: overlay__show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog__content__company__management {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: content__show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 6;
}

.dialog__content__company__management:focus {
  outline: none;
}

.dialog__content__company__management .dialog__title {
  margin: 0 0 15px 0;
  color: var(--font-color-default);
  font-size: 1.125rem;
  font-family: var(--font-bold);
}

.dialog__content__company__management .dialog__description {
  margin: 0 0 25px 0;
  color: var(--font-color-default);
  font-size: 1rem;
  line-height: 1.5;
}

.dialog__content__company__management .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}
.dialog__content__company__management .button.enable {
  background-color: var(--blue);
  color: #fff;
}
.dialog__content__company__management .button.enable:hover {
  background-color: var(--blue-hover);
}
.dialog__content__company__management .button.enable:focus {
  box-shadow: 0 0 0 2px var(--blue-hover);
}

.dialog__content__company__management .button.disable {
  background-color: rgb(212, 212, 212);
  color: rgb(112, 112, 112);
}
.dialog__content__company__management .button.disable:focus {
  box-shadow: 0 0 0 2px var(--blue-hover);
}

.dialog__content__company__management .icon__button {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 10px;
  right: 10px;
}
.dialog__content__company__management .icon__button:hover {
  background-color: var(--violet-4);
}
.dialog__content__company__management .icon__button:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.dialog__content__company__management .fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.dialog__content__company__management .label {
  font-size: 15px;
  color: var(--violet-11);
  width: 90px;
  text-align: right;
}

.dialog__content__company__management .input {
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  height: 35px;
}
.dialog__content__company__management .input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

.dialog__content__company__management .radio__group__root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem 0 0 0;
}

.dialog__content__company__management .radio__group__item {
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 15%;
  border: 1px solid var(--font-color-default);
}
.dialog__content__company__management .radio__group__item:hover {
  background-color: var(--violet-3);
}
.dialog__content__company__management .radio__group__item:focus {
  box-shadow: 0 0 0 2px black;
}

.dialog__content__company__management .radio__group__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.dialog__content__company__management .radio__group__indicator::after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 25%;
  background-color: var(--font-color-default);
}

.dialog__content__company__management .radio__group__option {
  display: flex;
  align-items: center;
}

.dialog__content__company__management .radio__group__label {
  font-size: 15px;
  line-height: 1;
  padding-left: 15px;
}

@keyframes overlay__show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes content__show {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
