@font-face {
  font-family: 'IBM Bold';
  src: url('../fonts/ibm-plex-sans-bold.ttf');
}

@font-face {
  font-family: 'IBM Regular';
  src: url('../fonts/ibm-plex-sans-regular.ttf');
}

:root {
  --font-bold: 'IBM Bold';
  --font-regular: 'IBM Regular';

  --font-color-default: #525252;

  --green-hover: #21c129;
  --green: #26aa2c;
  --red-hover: #da4b4b;
  --red: #c90000;
  --blue: #00a3ff;
  --blue-hover: #188fd3;
  --ultra-light-gray: #525252;
  --light-gray: #484848;
  --dark-gray: #424242;
}

.main {
  min-height: 100vh;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 3rem 0;
}

.title {
  font-family: var(--font-bold);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
