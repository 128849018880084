.account__created {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
}

.account__created img {
  width: 320px;
  margin-bottom: 3rem;
}

.account__created h1 {
  font-family: var(--font-bold);
  font-size: 1.125rem;
  text-align: center;
}

.account__created .back__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: var(--green-hover);
  border-radius: 4px;
  cursor: pointer;
}
