@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/red.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button {
  all: unset;
}

.alert__dialog__overlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  z-index: 5;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.alert__dialog__content {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 6;
}

.alert__dialog__content:focus {
  outline: none;
}

.alert__dialog__title {
  margin: 0 0 15px 0;
  color: var(--font-default-color);
  font-size: 1.125rem;
  font-family: var(--font-bold);
}

.alert__dialog__description {
  margin: 0 0 25px 0;
  color: var(--font-default-color);
  font-size: 1rem;
  line-height: 1.5;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}

.button.red {
  background-color: var(--red);
  color: #fff;
}

.button.red:hover {
  background-color: var(--red-hover);
}

.button.red:focus {
  box-shadow: 0 0 0 2px var(--red-hover);
}

.button.green {
  background-color: var(--green);
  color: #fff;
}

.button.green:hover {
  background-color: var(--green-hover);
}

.button.green:focus {
  box-shadow: 0 0 0 2px var(--green-hover);
}

.change__status__confirmation .active__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #26aa2c;
  border-radius: 5px;
  padding: 4px;
  width: 100px;
  cursor: pointer;
}

.change__status__confirmation .inactive__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 4px;
  width: 100px;
  background-color: #b24304;
  cursor: pointer;
}

.change__status__confirmation .active__button span,
.change__status__confirmation .inactive__button span {
  font-size: 12px;
  font-family: var(--font-bold);
  color: #fff;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
