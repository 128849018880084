@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button,
fieldset,
input {
  all: unset;
}

.add__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #00a3ff;
  border-radius: 5px;
}

.dialog__overlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  z-index: 5;
  animation: overlay__show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog__content {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: content__show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 6;
}

.dialog__content:focus {
  outline: none;
}

.dialog__title {
  margin: 0 0 15px 0;
  color: var(--font-default-color);
  font-size: 1.125rem;
  font-family: var(--font-bold);
}

.dialog__description {
  margin: 0 0 25px 0;
  color: var(--font-default-color);
  font-size: 1rem;
  line-height: 1.5;
}

.dialog__options {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  border-bottom: 1px solid var(--font-color-default);
  padding: 12px;
}

.dialog__option:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

.option__description {
  color: var(--font-default-color);
  font-size: 1rem;
  line-height: 1.5;
}

.navigation__menu__link {
  padding: 8px 22px;
  outline: none;
  user-select: none;
  line-height: 1;
  border-radius: 4px;
  font-family: var(--font-bold);
  color: var(--font-color-default);
}

.help {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 0 0;
  gap: 1rem;
}

.navigation__menu__link:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.navigation__menu__link:hover {
  background-color: var(--violet-3);
}

.navigation__menu__link {
  display: block;
  text-decoration: none;
  font-size: 15px;
  line-height: 1;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}
.button.blue {
  background-color: var(--blue);
  color: #fff;
}
.button.blue:hover {
  background-color: var(--blue-hover);
}
.button.blue:focus {
  box-shadow: 0 0 0 2px var(--blue-hover);
}

.icon__button {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 10px;
  right: 10px;
}
.icon__button:hover {
  background-color: var(--violet-4);
}
.icon__button:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.label {
  font-size: 15px;
  color: var(--violet-11);
  width: 90px;
  text-align: right;
}

.input {
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  height: 35px;
}
.input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

@keyframes overlay__show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes content__show {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
