@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/violet.css';
@import '@radix-ui/colors/mauve.css';

.signup {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
}

.signup .form__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
}

.signup .logo__app {
  width: 284px;
  margin-bottom: 2rem;
}

.form__box .form__box__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
}

.form__box .form__box__title h2 {
  font-family: var(--font-bold);
  font-size: 2rem;
}

.form__box .form__box__title hr {
  border: 1px solid var(--ultra-light-gray);
  width: 100%;
}

/* reset */
input,
button {
  all: unset;
  box-sizing: border-box;
}

.form__root__signup {
  width: 300px;
}

.form__root__signup .form__field {
  width: 100%;
  display: grid;
  margin-bottom: 10px;
}

.form__root__signup .form__label {
  font-family: var(--font-bold);
  font-size: 15px;
  font-weight: 700;
  line-height: 35px;
  color: var(--ultra-light-gray);
}

.form__root__signup .form__message {
  font-size: 13px;
  color: var(--ultra-light-gray);
  opacity: 0.8;
}

.form__root__signup .input__login {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  font-family: var(--font-bold);
  font-size: 15px;
  color: var(--ultra-light-gray);
  background-color: #cecece;
  box-shadow: 0 0 0 1px var(--black-a9);
}

.form__root__signup .input__login:hover {
  box-shadow: 0 0 0 1px black;
}

.form__root__signup .input__login:focus {
  box-shadow: 0 0 0 2px black;
}

.form__root__signup .input__login::selection {
  background-color: var(--black-a9);
  color: white;
}

.form__root__signup .input__login {
  padding: 0 10px;
  height: 35px;
  line-height: 1;
}

.button__signup {
  font-family: var(--font-bold);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 30px;
  cursor: pointer;
}

.button__signup.violet {
  color: var(--ultra-light-gray);
  border: 1px solid var(--black-a7);
}

.button__signup.violet:hover {
  background-color: var(--violet-5);
}

.button__signup.green {
  color: #fff;
  border: 1px solid var(--green-8);
}

.button__signup.green:hover {
  background-color: var(--green-7);
}

.button__signup.green.enabled {
  background-color: var(--green-8);
  color: #fff;
}

.button__signup.green.disabled {
  background-color: rgb(212, 212, 212);
  color: rgb(156, 156, 156);
  cursor: unset;
}
