@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

.companies .bold {
  font-family: var(--font-bold);
}

.companies .header {
  margin-bottom: 2rem;
}

.companies .table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
}

.companies .table__header .add__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #00a3ff;
  border-radius: 5px;
}

.companies table {
  width: 100%;

  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #f1e4e4;
  border: 1px solid #9b9b9b;
  border-radius: 8px;
}

.companies .table__footer {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.companies table tbody:before {
  padding-left: 1rem;
  line-height: 1rem;
  content: '.';
  color: #d9d9d9;
  display: block;
}

.companies table tbody tr td {
  padding: 5px 0;
}

.companies table thead tr th {
  font-family: var(--font-bold);
}

/* select */
/* reset */
button {
  all: unset;
}

.companies .select__trigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: #f1e4e4;
  border: 1px solid #9b9b9b;
  font-family: var(--font-bold);
  color: var(--font-color-default);
}

.companies .select__trigger:hover {
  background-color: var(--mauve-3);
}

.companies .select__trigger[data-placeholder] {
  color: var(--font-color-default);
}

.companies .select__icon {
  color: Var(--violet-11);
}

.companies .select__content {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
}

.companies .select__viewport {
  padding: 5px;
}

.companies .select__item {
  font-size: 13px;
  line-height: 1;
  color: var(--font-color-default);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}

.companies .select__item[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.companies .select__item[data-highlighted] {
  outline: none;
  background-color: var(--font-color-default);
  color: var(--violet-1);
}

.companies .select__label {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.companies .select__separator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.companies .select__item__indicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.companies .select__scroll__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}
